import { PUBLICATION_EXPERT_DATA_IMPORT } from '../../model.js';
require('./publication-overview.scss');

export default PublicationOverviewDirective;

// @ngInject
function PublicationOverviewDirective() {
  return {
    restrict: 'E',
    scope: {
      initialPublicationProvider: '&initialPublication',
      canEditProvider: '&canEdit',
    },
    bindToController: true,
    controller: PublicationOverviewController,
    controllerAs: 'publicationOverviewCtrl',
    template: require('./publication-overview.html'),
    replace: true
  };
}

// @ngInject
function PublicationOverviewController($scope, $state, $location, SectionDetailsService, PublicationDetailsService, ImportFileService) {
  var publicationOverviewCtrl = this;

  publicationOverviewCtrl.onSectionSelected = onSectionSelected;
  publicationOverviewCtrl.onDelete = onDelete;
  publicationOverviewCtrl.onCreateSection = onCreateSection;
  publicationOverviewCtrl.exportStatus = exportStatus;
  publicationOverviewCtrl.getErrorReportingLink = getErrorReportingLink;
  publicationOverviewCtrl.onUploadDemand = onUploadDemand;
  publicationOverviewCtrl.isApproved = true;
  $scope.$watch(publicationOverviewCtrl.initialPublicationProvider, (initialPublication) => {
    if (!initialPublication) {
      return;
    }

    // Create a copy of the immutable initialPublication
    publicationOverviewCtrl.publication = _.defaults({label: "--- label absent ---"}, initialPublication);
      if(publicationOverviewCtrl.publication.publication.approved == null){
        publicationOverviewCtrl.isApproved = false;
      }

    publicationOverviewCtrl.errorReportingLink = getErrorReportingLink();
  });

  $scope.$watch(publicationOverviewCtrl.canEditProvider, (canEdit) => {
    publicationOverviewCtrl.canEdit = canEdit;
  });

  function onSectionSelected(sectionId) {

    //let section = _.find(publicationOverviewCtrl.publication.section, section => { return section.id === sectionId; });

    //if (section.canEdit || section.canDelete){
      $state.go('section-details', {publicationId: publicationOverviewCtrl.publication.publication.id, sectionId});
    //}
  }


  function onDelete(sectionId) {
    SectionDetailsService.deleteSection(sectionId);
  }

  function onCreateSection() {
    $state.go('section-new', {publicationId: publicationOverviewCtrl.publication.publication.id});
  }

  function exportStatus() {
    PublicationDetailsService.exportStatus(publicationOverviewCtrl.publication.publication.id);
  }

  function getErrorReportingLink() {
    let description = "Problème détecté dans la publication suivante *" + publicationOverviewCtrl.publication.publication.label +"*"
    let subject = "[Statuts - Signalement d'erreur] pour la publication N°" +publicationOverviewCtrl.publication.publication.id;
    let link = $location.protocol()+"://"+$location.host()+"/#/publication/"+publicationOverviewCtrl.publication.publication.id;

    return "https://redmine.biotope.fr/projects/shuriken-mobile-anomalies/issues/new?issue[tracker_id]=1"+
    "&issue[subject]="+encodeURIComponent(subject)+
    "&issue[assigned_to_id]=1306"+ //groupe des référents Shuriken
    "&issue[category_id]=55"+
    "&issue[description]="+encodeURIComponent(description) + " ("+encodeURIComponent(link)+") %0A %0A %0A %0A[Décrire ici la suite du problème rencontré]";
  }

  function onUploadDemand() {


    $state.go('import', {publicationId: publicationOverviewCtrl.publication.publication.id});

  }



}
