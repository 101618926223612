import _ from 'lodash';

import { PUBLICATION_EXPERT_DATA_IMPORT } from '../model.js';

const EVENTS = {
  IMPORT_FILE_LOADING: 'shu-import.import-file-loading',
  IMPORT_FILE_LOAD_SUCCESS: 'shu-import.import-file-success',
  IMPORT_FILE_LOAD_ERROR: 'shu-import.import-file-load-error',
  IMPORT_FILE_PROCESSING: 'shu-import.import-file-processing',
  IMPORT_FILE_PROCESS_ERROR: 'shu-import.import-file-process-error',
  // Action lancée quand un des champs du formulaire de recherche est modifié
  TAXON_SEARCH_QUERY_UPDATE: 'shu-import.taxon-search-query-update',
  // Actions lancées quand l'utilisateur lance une recherche
  TAXON_SEARCH_RESULTS_LOADING: 'shu-import.taxon-search-results-loading',
  TAXON_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-import.taxon-search-results-load-success',
  TAXON_SEARCH_RESULTS_LOAD_ERROR: 'shu-import.taxon-search-results-load-error',
  // Actions lancées quand l'utilisateur consulte les résultats suivants d'une recherche
  TAXON_SEARCH_NEXT_RESULTS_LOADING: 'shu-import.taxon-search-next-results-loading',
  TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS: 'shu-import.taxon-search-next-results-load-success',
  TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR: 'shu-import.taxon-search-next-results-load-error',
  TAXON_CHECK_SUCCESS: 'shu-import.taxon-check-success',
  TAXON_CHECK_ERROR: 'shu-import.taxon-check-error',
  TAXON_CHECK_PROCESSING: 'shu-import.taxon-check-processing'

};
const LIMIT = 20;
export default ImportFileService;

// @ngInject
function ImportFileService($rootScope, $state,ImportRepository, ImportFileStore, LxNotificationService, UrlResolverService, AuthStore) {
  return { onUploadFile, onRemoveFile, buildFileList, onUpdateForm, loadNextResults, onConsolidationValidate, onGetMask};


  function onConsolidationValidate(publicationId, data){

    $rootScope.$broadcast(EVENTS.TAXON_CHECK_PROCESSING);

    ImportRepository.consolidationValidate(publicationId, data)
      .then(
        (result) => {
          LxNotificationService.success('Consolidation validée.');
          $state.go('publication-overview', {publicationId: publicationId});
          $rootScope.$broadcast(EVENTS.TAXON_CHECK_SUCCESS);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue lors de la validation. ' + (error.data || ''),  true);
          $rootScope.$broadcast(EVENTS.TAXON_CHECK_ERROR);

        }
      );


  }

  function onUpdateForm(query, selectedReferentielType, selectedReferentielVersion ) {

    $rootScope.$emit(EVENTS.TAXON_SEARCH_QUERY_UPDATE, {query, selectedReferentielType,selectedReferentielVersion });
    // On ne requête le serveur qu'à partir de 3 caractères
    if (!query || query.length <= 2 || !selectedReferentielType) {
      return;
    }

    $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOADING);
        ImportRepository.query(query, selectedReferentielType, selectedReferentielVersion, null, 0, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOAD_SUCCESS, result.taxons, result.count, {query, selectedReferentielType,selectedReferentielVersion });

      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''), true);
        $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOAD_ERROR, {query, selectedReferentielType});
      });
  }


  function loadNextResults(query, selectedReferentielType, selectedReferentielVersion, currentOffset) {
    $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOADING);

    ImportRepository.query(query, selectedReferentielType, selectedReferentielVersion, null, currentOffset, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, result.taxons, result.count, {query, selectedReferentielType, selectedReferentielVersion});
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''), true);
        $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR, {query, selectedReferentielType, selectedReferentielVersion});
      });
  }

  function buildFileList(fileState, studyId) {
    let files = [];
    // Ajout des couches MbTiles puis KML
    _.forEach(_.filter(fileState.files, (l) => l.format === STUDY_EXPERT_DATA_IMPORT.XLSX), (file) => {
      var fileInfo = {
        id: file.id,
        name: file.name,
        xlsx: true,
      //  remote: !IS_CORDOVA,
        headers: {'Authorization': 'Bearer ' + AuthStore.getState().token},
        url: UrlResolverService.resolveUrl(ImportConfiguration.studyImportEndpoint, {studyId, importFileId: file.id})
      };
      files.push(fileInfo);
    });
    _.forEach(_.filter(fileState.files, (l) => l.format === STUDY_EXPERT_DATA_IMPORT.SQLITE), (file) => {
      var fileInfo = {
        id: file.id,
        name: file.name,
        sqlite: true,
        filepath: file.filepath
      };
      files.push(fileInfo);
    });
    return files;
  }

function onGetMask(publicationId){
  ImportRepository.getInputMask(publicationId)
    .then(
      (error) => {
        LxNotificationService.error('Une erreur est survenue à récupération du masque de saisie. ' + (error.data || ''));
      }
    );
}

  function onUploadFile(publicationId, fileName, fileInfo, refid) {

    $rootScope.$broadcast(EVENTS.IMPORT_FILE_PROCESSING);

    ImportRepository.addFile(publicationId, fileName, fileInfo, refid)
      .then(
        (file) => {
          LxNotificationService.success('Fichier en traitement.');

          $rootScope.$broadcast(EVENTS.IMPORT_FILE_LOAD_SUCCESS, file);
        },
        (error) => {
          console.log(error.data)
          LxNotificationService.error('Une erreur est survenue au chargement du fichier. ' + (error.data || ''),true);
          $rootScope.$broadcast(EVENTS.IMPORT_FILE_PROCESS_ERROR);

        }
      );
  }


  function onRemoveFile(studyId, importFileId) {
    $rootScope.$broadcast(EVENTS.IMPORT_FILE_PROCESSING);

    ImportRepository.removeFile(studyId, importFileId)
      .then(
        (files) => {
          LxNotificationService.success('Fichier supprimé.');
          $rootScope.$broadcast(EVENTS.IMPORT_FILE_LOAD_SUCCESS, files);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la suppression du fichier. ' + (error.data || ''), true);
          $rootScope.$broadcast(EVENTS.IMPORT_FILE_PROCESS_ERROR);
        }
      );
  }


}
export { EVENTS };
