import * as models from '@biotope/shuriken-status-api'
import { TaxaStatusApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';


export const EVENTS = {
    TAXA_STATUS_DELETION_DONE: 'SHU-TAXA_STATUS_DELETION_DONE',
    TAXA_STATUS_DETAILS_PROCESS_ERROR: 'SHU-TAXA_STATUS_DETAILS_PROCESS_ERROR',
    TAXA_STATUS_UPDATE_DONE: 'SHU-TAXA_STATUS_UPDATE_DONE',
    TAXA_STATUS_UPDATE_ERROR: 'SHU-TAXA_STATUS_UPDATE_ERROR'
}

export class TaxaStatusService {

    private taxaStatusApi: TaxaStatusApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, TaxaStatusApi: TaxaStatusApi) {
      this.taxaStatusApi = TaxaStatusApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public updateTaxaStatus(taxaStatus: models.TaxaStatus) {

      this.taxaStatusApi.updateTaxaStatus(taxaStatus.id, taxaStatus)
        .then(
          () => {
            this.toaster.success('Statut modifié avec succès');
            this.$rootScope.$emit(EVENTS.TAXA_STATUS_UPDATE_DONE);
          },
          (error) => {
            this.toaster.error('Une erreur est survenue à la sauvegarde du statut. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.TAXA_STATUS_UPDATE_ERROR);
          }
        );
    }

    public deleteTaxaStatus(taxaStatusId: number) {
        return this.taxaStatusApi.deleteTaxaStatus(taxaStatusId)
        .then(
            (response) => {
            this.toaster.success('Statut supprimée avec succès');
            this.$rootScope.$emit(EVENTS.TAXA_STATUS_DELETION_DONE, _.defaults({}, response.data));
          }, (error: any) => {
            this.toaster.error('Une erreur est survenue à la suppression du statut. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.TAXA_STATUS_DETAILS_PROCESS_ERROR, error);
          });
    }
}
