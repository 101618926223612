import _ from 'lodash';
import angular from 'angular';

import { EVENTS as SECTION_SERVICE_STORE_EVENTS } from '../../stores/section-service.store';
import { EVENTS as TAXA_STATUS_SERVICE_EVENTS } from '../../../taxa-status/services/taxa-status.service';

require('./section-statutes-list.scss');

export default SectionStatutesListDirective;

// @ngInject
function SectionStatutesListDirective() {
  return {
    restrict: 'E',
    scope: {
      section: '=',
      sectionId: '@',
      isModeratorProvider: '&isModerator',
    },
    bindToController: true,
    controller: SectionStatutesListController,
    controllerAs: 'sectionStatutesListCtrl',
    template: require('./section-statutes-list.html'),
    replace: true
  };
}

// @ngInject
function SectionStatutesListController($scope, $rootScope, SectionService, SectionServiceStore, LxNotificationService, TaxaStatusService) {
  var sectionStatutesListCtrl = this;

  sectionStatutesListCtrl.displayOriginalStatus = false;
  sectionStatutesListCtrl.sectionStatutesState = {};
  sectionStatutesListCtrl.headers = [];
  sectionStatutesListCtrl.searchText = "";

  sectionStatutesListCtrl.onRemove = onRemove;
  sectionStatutesListCtrl.onEdit = onEdit;
  sectionStatutesListCtrl.isLoading = isLoading;
  sectionStatutesListCtrl.filterTaxa = filterTaxa;

  sectionStatutesListCtrl.getStatusCssClass = getStatusCssClass;

  $scope.$on(SECTION_SERVICE_STORE_EVENTS.SECTION_STATUTES_STATE_CHANGED, reloadSectionStatutes);
  $rootScope.$on(TAXA_STATUS_SERVICE_EVENTS.TAXA_STATUS_DELETION_DONE, loadSectionStatutes);
  $rootScope.$on(TAXA_STATUS_SERVICE_EVENTS.TAXA_STATUS_UPDATE_DONE, loadSectionStatutes);

  $scope.$watch(() => sectionStatutesListCtrl.sectionId, () => {
    if (sectionStatutesListCtrl.sectionId) {
      //first, load statutes types
      loadSectionStatutes();
    }
  });

  $scope.$watch(() => sectionStatutesListCtrl.section, () => {
    //console.log(sectionStatutesListCtrl.section);
    //console.log(sectionStatutesListCtrl.section.statusType);
    if (sectionStatutesListCtrl.section) {
      //first, load statutes types
      sectionStatutesListCtrl.headers = [];
      var section = sectionStatutesListCtrl.section;
      //var section = angular.toJson(sectionStatutesListCtrl.section)
      var header = {
        "headerLabel": section.statusTypeKey,
        "statusTypeKey": section.statusTypeKey,
        "terrUnitKey": section.terrUnitKey,
      }
      sectionStatutesListCtrl.headers.push(header);

      //console.log(sectionStatutesListCtrl.section);

    }
  });

  $scope.$watch(sectionStatutesListCtrl.isModeratorProvider, (isModerator) => {
    sectionStatutesListCtrl.isModerator = isModerator;
  });


  function loadSectionStatutes() {
      SectionService.getSectionStatutes(sectionStatutesListCtrl.sectionId);
  }

  function reloadSectionStatutes() {

    sectionStatutesListCtrl.sectionStatutesState = SectionServiceStore.getStatutesStates();

    if (sectionStatutesListCtrl.sectionStatutesState.error) {
      return;
    }

    // Create a copy of the immutable initialPublication
    sectionStatutesListCtrl.sectionStatutes = _.toArray(_.defaultsDeep({}, sectionStatutesListCtrl.sectionStatutesState.statutes));
  }

  function isLoading() {
    //return false;
    return (sectionStatutesListCtrl.sectionStatutesState && sectionStatutesListCtrl.sectionStatutesState.loading);
  }

  function filterTaxa(sectionStatus) {
    return filterByTaxonomicGroup(sectionStatus.taxa)/* || filterByName(sectionStatus.taxa)*/;
  }

  function filterByTaxonomicGroup(taxa) {
    return (taxa.txGroup === sectionStatutesListCtrl.section.taxonomicGroup);
  }

  function filterByName(taxa) {
    return _.includes(taxa.txQuotedName, sectionStatutesListCtrl.searchText);
  }

  function onRemove(taxaStatus) {

     LxNotificationService.confirm('Attention', 'Êtes-vous sur de vouloir supprimer ce statut ?',
               {cancel: 'Non', ok: 'Oui'},
               (answer) => (answer && TaxaStatusService.deleteTaxaStatus(taxaStatus.id)));
   }

   function onEdit(newTaxaStatus) {
     TaxaStatusService.updateTaxaStatus(newTaxaStatus);
   }

   function getStatusCssClass(stKey, svKey) {
    if(svKey != undefined){
     return "R-"+stKey+"-"+svKey.replaceAll(' ', '');
    }
    return;
   }
}
