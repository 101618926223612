export default ImportFileRemoteRepository;

// @ngInject
function ImportFileRemoteRepository(ImportConfiguration, TaxonConfiguration, TerrUnitConfiguration, $http) {
  return { query, queryTerrUnit, addFile, consolidationValidate, getInputMask, getLastRefVersion };


  function getLastRefVersion(txRefType){
    return $http({
      method: 'GET',
      url: ImportConfiguration.lastRefVersionEndpoint,
      pathParams: {txRefType}
    }).then((response) => response.data);
  }

  function query(query, txRefType, txRefVersion, txGroupKeys, offset, limit) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.searchEndpoint,
      pathParams: {txRefType, txRefVersion, query},
      params: {offset, limit}
    }).then((response) => response.data);

  }

  function queryTerrUnit(query) {
    return $http({
      method: 'GET',
      url: TerrUnitConfiguration.searchTerrUnitEndPoint,
      params: {query}
    }).then((response) => response.data);

  }

  function addFile(publicationId, fileName, fileInfo, refid) {
    return $http({
      method: 'POST',
      url: ImportConfiguration.publicationImportEndpoint,
      headers: { 'Content-Type': undefined},
      pathParams: {publicationId},
      data: toFormData(fileName, fileInfo, refid),
      responseType: 'text'
    }).then((response) => response.data);
  }

  function toFormData(fileName, fileInfo, refid) {
    var formData = new FormData();
    formData.append('referentielId', refid);
    formData.append('file_to_upload', fileInfo.file);
    return formData;
  }


function getListOfWrongTax(body) {

  //console.log(body);
}

function consolidationValidate(publicationId, data) {
  return $http({
    method: 'POST',
    url: ImportConfiguration.consolidationCheckEndpoint,
    headers: { 'Content-Type': 'application/json'},
    pathParams: {publicationId},
    data: data
  }).then((response) => response.data);
}

function getInputMask(publicationId) {
  return $http({
    method: 'GET',
    url: ImportConfiguration.inputMaskEndpoint,
    pathParams: { publicationId },
    responseType: 'arraybuffer'
  }).then((response) => {
     let options = { type: 'application/octet-stream;charset=utf-8;' };
     let filename = 'input-mask-'+publicationId+'.csv';
     var disposition = response.headers()['Content-Disposition'];
     createAndDownloadBlobFile(response.data, options, filename);
});
}

function createAndDownloadBlobFile(body, options, filename) {

    var blob = new Blob([body], options);

    var link = document.createElement("a");

    // Browsers that support HTML5 download attribute
    if (link.download !== undefined)
    {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}

}
