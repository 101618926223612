import 'core-js/es6/symbol';
import 'jquery';
import 'velocity-animate';
import 'moment';
import * as angular  from 'angular';
import 'angular-sanitize';
import 'angular-i18n/fr-fr.js';
import '../../libs/lumx/dist/lumx.js';
import '@uirouter/angularjs'
import 'angular-filter'

//import statusApiModule from '@biotope/shuriken-status-api'

import * as statusApi from '@biotope/shuriken-status-api'
import * as referentielApi from '@biotope/shuriken-ng-referentiel-api'

// Workaround pour que les modifications sur le fichier index.html soient
// surveillées par Webpack
// Le if permet de ne pas ajouter cette dépendence en mode production
if (process.env.NODE_ENV === 'development') {
  require('raw-loader!./index.html');
}

require('./app.scss');

import baseModule from '../../share/src/base/base.module';
import authModule from '../../share/src/auth/auth.module';
import profileModule from '../../share/src/profile/profile.module';
import settingsModule from '../../share/src/settings/settings.module';
import expertModule from '../../share/src/expert/expert.module';
import txGroupModule from '../../share/src/txgroup/txgroup.module';
import publicationModule from '../../share/src/publication/publication.module';
import sectionModule from '../../share/src/section/section.module';
import statusTypeModule from '../../share/src/status-type/status-type.module';
import terrUnitModule from '../../share/src/terr-unit/terr-unit.module';
import taxonModule from '../../share/src/taxon/taxon.module.js';
import importModule from '../../share/src/import/import-file.module.js';
import moderationModule from '../../share/src/moderation/moderation.module';
import dictionnaryModule from '../../share/src/dictionary/dictionary.module';
import taxaStatusModule from '../../share/src/taxa-status/taxa-status.module';
import statusValueModule from '../../share/src/status-value/status-value.module';
import asyncModule from '../../share/src/async/async.module';
import studyModule from '../../share/src/study/study.module';
import consolidationModule from '../../share/src/consolidation/consolidation.module';
import mainRouterConfig from '../../share/src/main-routes';

//import zenaModule from '../../share/src/zena/zena.module';

//https://stackoverflow.com/questions/55465471/failed-to-instantiate-module-in-angularjs-due-to-missing-loaded-provider
import { BasePath } from '../../share/src/base-path-provider';
import { STATUS } from '../../share/src/base/services/url-resolver.service';
import { REFERENTIEL } from '../../share/src/base/services/url-resolver.service';

statusApi.apiModule
// Define provider
/*.provider('basePath', BasePath)
// Configure provider (note the suffix "Provider" here)
.config(['basePathProvider', function(basePathProvider: BasePath) {
    basePathProvider.setBasePath(STATUS);
}]);*/
  .provider('basePath', BasePath)
  .config(/** @ngInject */ (basePathProvider: BasePath) => {
        basePathProvider.setBasePath(STATUS);
});

referentielApi.apiModule
  .provider('referentielApiBasePath', BasePath)
  .config(/** @ngInject */ (referentielApiBasePathProvider: BasePath) => {
        referentielApiBasePathProvider.setBasePath(REFERENTIEL);
});

angular.module('shuriken-status', [
  baseModule.moduleName,
  profileModule.moduleName,
  settingsModule.moduleName,
  authModule.moduleName,
  expertModule.moduleName,
  txGroupModule.moduleName,
  publicationModule.moduleName,
  sectionModule.moduleName,
  statusTypeModule.moduleName,
  terrUnitModule.moduleName,
  //zenaModule.moduleName,
  'api', //statusApi.apiModule
  'referentiel-api', //referentielApi.apiModule
  taxonModule.moduleName,
  importModule.moduleName,
  moderationModule.moduleName,
  dictionnaryModule.moduleName,
  taxaStatusModule.moduleName,
  statusValueModule.moduleName,
  studyModule.moduleName,
  consolidationModule.moduleName,
  'lumx',
  'lumx.utils',
  'ngLocale',
  'ngSanitize',
  'ui.router',
  'angular.filter',
   asyncModule.moduleName
])
.config(mainRouterConfig);

angular.element(document.body).ready(function() {
  angular.bootstrap(document.body, ['shuriken-status']);
});
