import * as models from '@biotope/shuriken-status-api'
import { RefApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur charge les statusType
  STATUS_TYPE_BY_KEY_LOADING: 'SHU-STATUS_TYPE_BY_KEY_LOADING',
  STATUS_TYPE_BY_KEY_LOAD_SUCCESS: 'SHU-STATUS_TYPE_BY_KEY_LOAD_SUCCESS',
  STATUS_TYPE_BY_KEY_LOAD_ERROR: 'SHU-STATUS_TYPE_BY_KEY_LOAD_ERROR',
}

export class StatusTypeService {

    private api: RefApi;
    private toaster: any;
    private $rootScope: any;
    //private $scope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, RefApi: RefApi) {
      this.api = RefApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
      //this.$scope = $scope;
    }

    public getStatusTypeById(id: number) {

      this.$rootScope.$emit(EVENTS.STATUS_TYPE_BY_KEY_LOADING);

      return this.api.getStatusById(id)
      .then(
          (response) => {
          let r = new Array();
          r.push(response.data);
          this.$rootScope.$emit(EVENTS.STATUS_TYPE_BY_KEY_LOAD_SUCCESS, r);
          return response.data;
        }, (error: any) => {
          //console.log(error);
          this.toaster.error('Une erreur est survenue lors de la recheche par id. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.STATUS_TYPE_BY_KEY_LOAD_ERROR, error);
        });
    }
}
